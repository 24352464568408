import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { IFacility } from "@grainger/common/definitions/facility";
import { IPillar } from "@grainger/common/definitions/pillar";
import { FacilityStatus } from "@grainger/common/enums/facility-status.enum";
import { map, Observable } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: "root"
})
export class FacilityService {
    private http = inject(HttpClient);

    getFacilities(): Observable<IFacility[]> {
        return this.http.get<IFacility[]>(`${environment.apiBaseUrl}/facilities`).pipe(
            map((facilities: IFacility[]) => {
                return facilities
                    .filter((facility: IFacility) => facility.status !== FacilityStatus.Archived)
                    .sort((a, b) => {
                        if (a.status === FacilityStatus.ComingSoon && b.status !== FacilityStatus.ComingSoon) {
                            return 1;
                        }
                        if (a.status !== FacilityStatus.ComingSoon && b.status === FacilityStatus.ComingSoon) {
                            return -1;
                        }
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                        return 0;
                    });
            })
        );
    }

    // TODO: Add Caching
    getFacility(urlSlug: string): Observable<IFacility | null> {
        return this.http.get<IFacility>(`${environment.apiBaseUrl}/facilities/${urlSlug}`).pipe(
            map((facility: IFacility) => {
                facility.pillars = this.sortPillars(facility.pillars ?? []);

                return facility;
            })
        );
    }

    private sortPillars(pillars: IPillar[]): IPillar[] {
        return pillars.sort((a, b) => {
            if (a.order < b.order) {
                return -1;
            }
            if (a.order > b.order) {
                return 1;
            }
            return 0;
        });
    }

    // TODO: GFE-26 Add method to get display departments
}
