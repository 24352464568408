import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { RouterModule } from "@angular/router";
import { UrlHelpers } from "../../core/utils/url.util";

@Component({
    selector: "grainger-arrow-button",
    standalone: true,
    imports: [CommonModule, RouterModule],
    templateUrl: "./arrow-button.component.html",
    styleUrl: "./arrow-button.component.scss"
})
export class ArrowButtonComponent implements OnInit {
    @Output() clicked: EventEmitter<void> = new EventEmitter<void>();

    @Input() handleInParent: boolean = false;
    @Input() text: string = "Button";
    @Input() path: string | string[] | null | undefined;
    @Input() arrowDirection: "left" | "right" = "left";
    @Input() disabled: boolean = false;

    public isExternal: boolean = false;

    ngOnInit(): void {
        if (this.path && !(this.path instanceof Array)) {
            this.isExternal = UrlHelpers.isExternalLink(this.path);
        }
    }

    public handleClick(event: any): void {
        if (this.handleInParent) {
            event.preventDefault();
        }

        this.clicked.emit();
    }
}
