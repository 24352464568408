import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { ITokenPayload } from "@grainger/common/definitions/auth";
import { IUser, UserProfile } from "@grainger/common/definitions/user";
import { RegisterUserDto } from "@grainger/common/dto/register-user.dto";
import { UpdatePasswordDto } from "@grainger/common/dto/update-password.dto";
import { UpdateUserProfileDto } from "@grainger/common/dto/update-user-profile.dto";
import { UserRole } from "@grainger/common/enums/user-role.enum";
import { BehaviorSubject, Observable, ReplaySubject, catchError, map, of, switchMap, tap } from "rxjs";
import { AUTH_TOKEN_STORAGE_KEY } from "../constants/site-info.constants";
import { InitUtil } from "../util/init.util";
import { StorageService } from "./storage.service";

@Injectable({
    providedIn: "root"
})
export class AuthService {
    private storageService = inject(StorageService);
    private http = inject(HttpClient);
    private environment = inject(InitUtil.APP_ENVIRONMENT);

    private readonly _token$: BehaviorSubject<string | null>;
    private readonly _userId$: BehaviorSubject<string | null>;
    private readonly _userRoles$: BehaviorSubject<string[] | null>;

    private readonly _user$: ReplaySubject<UserProfile | null> = new ReplaySubject(1);
    public readonly user$: Observable<UserProfile | null>;
    public readonly isAuthenticated$: Observable<boolean>;
    public readonly isAdmin$: Observable<boolean>;
    public readonly isUserAdmin$: Observable<boolean>;
    public readonly isSuperAdmin$: Observable<boolean>;

    constructor() {
        let token = this.getTokenFromStorage();
        const { isValid, userId, userRoles } = this.parseToken(token);
        if (!isValid) {
            token = null;
        }

        this._token$ = new BehaviorSubject<string | null>(token);
        this._userId$ = new BehaviorSubject<string | null>(userId);
        this._userRoles$ = new BehaviorSubject<string[] | null>(userRoles);

        setTimeout(() => {
            this._token$.pipe(switchMap(() => this.getUser())).subscribe(this._user$);
        }, 0);

        this.user$ = this._user$.asObservable();
        this.isAuthenticated$ = this._token$.pipe(map((token) => !!token));
        this.isAdmin$ = this._userRoles$.pipe(
            map(
                (roles) =>
                    roles?.includes(UserRole.Admin) ||
                    roles?.includes(UserRole.UserAdmin) ||
                    roles?.includes(UserRole.SuperAdmin) ||
                    false
            )
        );
        this.isUserAdmin$ = this._userRoles$.pipe(
            map((roles) => roles?.includes(UserRole.UserAdmin) || roles?.includes(UserRole.SuperAdmin) || false)
        );
        this.isSuperAdmin$ = this._userRoles$.pipe(map((roles) => roles?.includes(UserRole.SuperAdmin) || false));
    }

    public login(email: string, password: string, allowedRoles?: UserRole[]): Observable<{ authToken: string } | null> {
        return this.http
            .post<{ authToken: string; user: UserProfile } | null>(`${this.environment.apiBaseUrl}/auth/login`, {
                email,
                password
            })
            .pipe(
                tap((response) => {
                    if (response?.authToken) {
                        if (allowedRoles?.length && !response.user.roles.some((r) => allowedRoles.includes(r))) {
                            throw new Error("User does not have the required role to access this application.");
                        }
                        this.storageService.setItem(AUTH_TOKEN_STORAGE_KEY, response.authToken);

                        const { userId, userRoles } = this.parseToken(response.authToken);
                        this._token$.next(response.authToken);
                        this._userId$.next(userId);
                        this._userRoles$.next(userRoles);
                    } else {
                        throw new Error("Error logging in");
                    }
                }),
                catchError((err) => {
                    this._token$.next(null);
                    this._userId$.next(null);
                    this._userRoles$.next(null);

                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 0) {
                            throw new Error(`Unable to log in. Please check your network connection.`);
                        } else if (err.error?.message === "Unauthorized") {
                            throw new Error(
                                "Unable to log in. Please check your credentials and try again or click Sign up to create an account."
                            );
                        } else {
                            throw new Error(err.error.message);
                        }
                    } else {
                        throw err;
                    }
                })
            );
    }

    public register(user: RegisterUserDto) {
        return this.http.post<number>(`${this.environment.apiBaseUrl}/auth/register`, user).pipe(
            catchError((err) => {
                console.log("error status: ", err.status);

                if (err.status === 0) {
                    // Only handle error case here
                    throw new Error(`Unable to register. Please check your network connection.`);
                } else {
                    throw new Error(
                        `Unable to register. If you have already registered and have forgotten your password, please click login instead and press forgot password.`
                    );
                }
            }),
            switchMap((status: number) => {
                if (status === 201) {
                    return this.login(user.email, user.password).pipe(
                        catchError((err) => {
                            console.log("error status: ", err.status);
                            throw new Error(
                                "Unable to log in after registering. Please check your network connection and click Log in to try again."
                            );
                        })
                    );
                } else {
                    throw new Error(
                        "Unable to register. If you have already registered and have forgotten your password, please click login instead and press forgot password."
                    );
                }
            })
        );
    }

    public getUser(): Observable<IUser | null> {
        if (!this.token) {
            return of(null);
        }
        return this.http
            .get<IUser>(`${this.environment.apiBaseUrl}/auth/profile`, {
                headers: {
                    Authorization: `Bearer ${this.token}`
                }
            })
            .pipe(catchError(() => of(null)));
    }

    public updatePassword(updatePasswordDetails: UpdatePasswordDto) {
        return this.http
            .patch(`${this.environment.apiBaseUrl}/auth/update-password`, {
                ...updatePasswordDetails
            })
            .pipe(
                catchError((err) => {
                    if (err.status === 0) {
                        throw new Error(`Unable to update password. Please check your network connection.`);
                    } else {
                        const apiError = err.error || null;

                        // TODO: Update API to return either a status code or a user friendly message
                        if (apiError && apiError.message?.toLowerCase().includes("expired")) {
                            throw new Error(
                                "The password reset link has expired. Please request a new one by pressing cancel and then click forgot password."
                            );
                        } else if (apiError && apiError.message?.toLowerCase().includes("invalid")) {
                            throw new Error("The password reset link is invalid.");
                        }

                        throw new Error(
                            "There was an error updating password for this user. Please click cancel and forgot password again for a new link. If you continue to experience issues, please contact your administrator."
                        );
                    }
                }),
                switchMap(() => {
                    return this.login(updatePasswordDetails.email, updatePasswordDetails.newPassword).pipe(
                        catchError((err) => {
                            if (err.status === 0) {
                                throw new Error(`Unable to register. Please check your network connection.`);
                            }

                            throw new Error(
                                "Unable to log in after updating password. Please click Log in and try again with your new credentials."
                            );
                        })
                    );
                })
            );
    }

    public updateProfile(user: UpdateUserProfileDto) {
        return this.http
            .post<IUser>(`${this.environment.apiBaseUrl}/auth/profile`, user)
            .pipe(tap((updatedUser) => this._user$.next(updatedUser)));
    }

    public logout(): void {
        this.storageService.removeItem(AUTH_TOKEN_STORAGE_KEY);
        this._token$.next(null);
        this._userRoles$.next(null);
    }

    public get token(): string | null {
        return this._token$.getValue();
    }

    public get userId(): string | null {
        return this._userId$.getValue();
    }

    public resetPasswordRequest(email: string) {
        return this.http
            .post<{ resetToken: string }>(`${this.environment.apiBaseUrl}/auth/request-reset-password`, {
                email
            })
            .pipe(
                catchError((err) => {
                    if (err.status === 0) {
                        throw new Error(`Unable to reset password. Please check your network connection.`);
                    }

                    throw new Error(
                        "Error resetting password. If you do not have an account, please click Cancel and then click Sign up."
                    );
                })
            );
    }

    private parseToken(token: string | null) {
        let userId: string | null = null;
        let userRoles: string[] | null = null;
        let isValid = false;
        try {
            if (token) {
                const payload: ITokenPayload = this.decodeJwt(token);
                userId = payload.sub;
                userRoles = payload.roles;

                const tokenExpired = this.jwtExpired(payload);

                if (!userId || !userRoles || tokenExpired) {
                    throw new Error("Invalid JWT");
                }
                isValid = true;
            }
        } catch (err) {
            console.error("Error decoding JWT: ", err);
            token = null;
            userId = null;
            userRoles = null;
        }

        return {
            isValid,
            userId,
            userRoles
        };
    }

    private getTokenFromStorage(): string | null {
        const token = this.storageService.getItem(AUTH_TOKEN_STORAGE_KEY);

        return token;
    }

    private decodeJwt(token: string) {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = decodeURIComponent(
            window
                .atob(base64)
                .split("")
                .map(function (c) {
                    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join("")
        );

        return JSON.parse(jsonPayload);
    }

    private jwtExpired(payload: ITokenPayload) {
        if (!payload.exp) {
            return false;
        }
        const exp = payload.exp * 1000;
        const now = new Date().getTime();

        return now > exp;
    }
}
