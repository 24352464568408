import { Injectable } from "@angular/core";
import { BehaviorSubject, delay, map, Subject } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class LoadingService {
    private _isLoadingQueue: BehaviorSubject<boolean[]> = new BehaviorSubject<boolean[]>([]);
    private _isLoading: Subject<boolean> = new Subject<boolean>();

    public getLoadingStatus() {
        return this._isLoadingQueue.pipe(
            map((queue) => queue.length > 0),
            delay(0)
        );
    }

    public setLoadingStatus(isLoading: boolean) {
        if (isLoading) {
            this._isLoadingQueue.next([...this._isLoadingQueue.getValue(), true]);
        } else {
            this._isLoadingQueue.next(this._isLoadingQueue.getValue().slice(1));
        }
    }
}
