import { Directive, HostBinding, inject, Input, OnChanges, SimpleChanges } from "@angular/core";
import { LoadingService } from "../services/loading.service";

@Directive({
    selector: "[graingerBackgroundImage]",
    host: {
        "[style.transition]": "'opacity 0.4s 0.25s ease-in-out'"
    },
    standalone: true
})
export class BackgroundImageDirective implements OnChanges {
    private img: HTMLImageElement;
    @Input() graingerBackgroundImage: string | null | undefined;

    private loaded = false;
    private error = false;

    private readonly loadingService = inject(LoadingService);

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["graingerBackgroundImage"]) {
            this.preloadImage(changes["graingerBackgroundImage"].currentValue);
        }
    }

    private preloadImage(url?: string): void {
        if (!url) {
            this.loaded = false;
            this.error = true;
            return;
        }

        this.loadingService.setLoadingStatus(true);
        this.loaded = false;
        this.error = false;
        const img = new Image();
        img.onload = () => {
            this.loaded = true;
            this.error = true;
            this.loadingService.setLoadingStatus(false);
        };

        img.onerror = () => {
            this.loaded = false;
            this.error = true;
            console.error(`Failed to load image: ${url}`);
            this.loadingService.setLoadingStatus(false);
        };
        img.src = url;
        this.img = img;
    }

    @HostBinding("style.background-image")
    public get backgroundImageStyle(): string {
        return this.graingerBackgroundImage && this.loaded ? `url(${this.graingerBackgroundImage})` : "none";
    }

    @HostBinding("style.opacity")
    public get opacity(): number {
        return this.loaded || this.error ? 1 : 0;
    }
}
